<template>
  <div class="compereContainer">
    <div>
      <!-- 搜索栏 -->
      <div>
        <el-form :inline="true" :model="dataForm">
          <el-form-item>
            <el-input placeholder="请输入主播名称" size="small" v-model="dataForm.anchorName" clearable
              @input="keywordChange"></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="dataForm.recordStatus" placeholder="按录制状态筛选" size="small" clearable
              @change="keywordChange">
              <el-option v-for="item in recordStatusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList()" size="small">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="compereList && compereList.length > 0">
        <!-- 状态栏 -->
        <div class="statusContainer">
          <div class="statusLeftContainer">
            <div>
              <div>
                <span>录制中：</span>
                <span style="color: red;">{{ recordNum }}</span>
                <span>，还可录制：</span>
                <span style="color: red;">{{ userProperty.totalMonitorNum == -1 ? "无限制" : userProperty.totalMonitorNum -
                  recordNum }}</span>
                <span style="margin-left: 15px;color: blue;cursor: pointer;" @click="toSetup">立即扩容</span>
              </div>
              <div v-if="detection">
                <span>已检测：{{ detectionTime }}</span>
              </div>
            </div>
          </div>
          <div style="display: flex;align-items: center;">
            <el-select v-model="recordMode" placeholder="录制格式" size="mini" :disabled="detection"
              style="width: 90px;margin-right: 10px;" @change="recordModeChange">
              <el-option v-for="(item, index) in recordModeList" :key="item" :label="item" :value="index">
              </el-option>
            </el-select>
            <el-select v-model="definition" placeholder="清晰度" size="mini" :disabled="detection"
              style="width: 90px;margin-right: 10px;" @change="definitionChange">
              <el-option v-for="(item, index) in definitionList" :key="item" :label="item" :value="index">
              </el-option>
            </el-select>
            <div class="startDetectionContainer" v-if="!detection" @click="startDetection"
              :style="detectionBtnClickTime > 0 ? 'cursor: no-drop;' : ''">
              开始检测录制分析{{ detectionBtnClickTime > 0 ? ("(" + this.detectionBtnClickTime + ")") : "" }}</div>
            <div class="stopDetectionContainer" v-if="detection" @click="stopDetection"
              :style="detectionBtnClickTime > 0 ? 'cursor: no-drop;' : ''">停止录制分析{{ detectionBtnClickTime > 0 ? ("(" +
                this.detectionBtnClickTime + ")") : "" }}</div>
          </div>
        </div>
        <!-- 主播列表 -->
        <div style="position: relative;">
          <!-- 列表标题 -->
          <div class="listTitleContainer">
            <div style="flex: 2;">主播</div>
            <div style="flex: 1;text-align: center;">自动录制分析</div>
            <div style="flex: 1;text-align: center;">录制状态</div>
            <div style="flex: 1;text-align: center;">大小时长</div>
            <div style="flex: 1;text-align: center;">在线人数</div>
            <div style="flex: 1;text-align: center;">行业</div>
            <div style="text-align: center; width: 144px;">操作</div>
          </div>
          <!-- 列表 -->
          <div class="tableContainer">
            <div v-for="(item, index) in compereList" :key="item.SecUid" class="listRowContainer"
              :style="index % 2 != 0 ? 'background: rgb(245,247,250)' : ''">
              <!-- 主播信息 -->
              <div class="listColContainer1">
                <img :src="item.AnchorAvatar" class="compereImg">
                <div class="compereInfoContainer">
                  <div class="compereName">{{ item.AnchorName }}</div>
                  <div class="compereStatusContainer">
                    <div v-if="item.LiveStatus == 0" class="compereNotPlayStatus">未检测</div>
                    <div v-if="item.LiveStatus == -1" class="compereNotPlayStatus">检测中</div>
                    <div v-if="item.LiveStatus == 4" class="compereNotPlayStatus">未开播</div>
                    <div v-if="item.LiveStatus == 2" class="comperePlayStatus">
                      <img src="@/assets/imgs/play.png" class="comperePlayStatusImg">
                      <div class="comperePlayStatusText">直播中</div>
                    </div>
                    <div class="compereResourceType"
                      v-if="item.AnchorPlatform == 'DouYinHomeLive' || item.AnchorPlatform == 'DouYinLive'">抖音</div>
                    <div class="compereResourceType" v-if="item.AnchorPlatform == 'TiktokLive'">tiktok</div>
                    <div class="compereResourceType" v-if="item.AnchorPlatform == 'KuaiShouLive'">快手</div>
                  </div>
                </div>
              </div>
              <!-- 自动录制 -->
              <div class="listColContainer">
                <el-switch v-model="item.IsAutoRecord" active-color="#13ce66" inactive-color="#DCE0E7"
                  @change="(value) => autoRecordChange(value, item.SecUid)"
                  v-if="item.IsAutoRecord === true || item.IsAutoRecord === false"></el-switch>
              </div>
              <!-- 录制状态 -->
              <div class="listColContainer recordText">
                <div v-if="item.RecordStatus == 0">未录制</div>
                <div v-if="item.RecordStatus == 1">
                  <div style=" color: green;">录制中</div>
                  <div style="margin-top: 4px;">{{ item.StartTime }}</div>
                </div>
                <div v-if="item.RecordStatus == 2">手动停止</div>
                <div v-if="item.RecordStatus == 3">录制完成</div>
                <div v-if="item.RecordStatus == 4">手动开启中</div>
              </div>
              <!-- 文件大小 -->
              <div class="listColContainer recordText">

                <!-- <div v-if="item.RecordStatus == 1 || item.RecordStatus == 2 || item.RecordStatus == 3"> -->
                <div v-if="item.RecordStatus == 1">
                  <div v-if="item.RecordStatus == 1">{{ '第' + (item.Paragraph + 1) + '段' }}</div>
                  <div style="margin-top: 1px;" v-if="configInfo.HideSize != 1">{{ item.VedioSizie ? item.VedioSizie +
                    "M" : "" }}</div>
                  <div style="margin-top: 1px;" v-if="configInfo.HideDuration != 1">{{ item.Duration }}</div>
                </div>
                <div v-else>-</div>
              </div>
              <!-- 在线人数 -->
              <div class="listColContainer">
                <span v-if="detection && item.LiveStatus == 2 && configInfo.OnlineNumber == 1">{{ item.OnlineNumber ?
                  item.OnlineNumber : 0 }}</span>
                <span v-else>-</span>
              </div>
              <!-- 行业 -->
              <div class="listColContainer" style="flex-direction: column;">
                <span v-if="item.TradeId">{{ tradeList.filter(tradeItem => tradeItem.id ==
                  item.TradeId)[0].name }}</span>
                <span v-else>-</span>
                <span style="cursor: pointer; color: #0077FF;" @click="showTrade(item.SecUid)">编辑</span>
              </div>
              <!-- 操作 -->
              <!-- <div class="listColContainer2">
                <div class="operate1">

                  <div class="compereHomepageText" @click="openToBrowser(item.HomeUrl)">主页</div>
                  <div class="compereHomepageText" @click="openDirectory(item.SecUid)">目录</div>
                  <div :class="item.LiveStatus == 2 ? 'compereHomepageText' : 'notAllowedCompereHomepageText'"
                    @click="previewVideo(item.SecUid, item.LiveStatus)">预览</div>
                </div>
                <div class="operate2">
                  <div class="compereHomepageText" @click="removeCompere(item)">删除</div>
                  <div
                    v-if="item.LiveStatus == 2 && (item.RecordStatus == 0 || item.RecordStatus == 2 || item.RecordStatus == 3) && detection && detectionBtnClickTime <= 0"
                    class="startRecordText" @click="startRecord(item.SecUid)"
                    :style="itemBtnClickData[item.SecUid] > 0 ? 'cursor: no-drop;' : ''">
                    开始录制{{ itemBtnClickData[item.SecUid] > 0 ? ("(" +
                      itemBtnClickData[item.SecUid] + ")") : "" }}</div>
                  <div v-if="item.RecordStatus == 1 && detectionBtnClickTime <= 0" class="endRecordText"
                    @click="stopRecord(item.SecUid)"
                    :style="itemBtnClickData[item.SecUid] > 0 ? 'cursor: no-drop;' : ''">
                    停止录制 {{
                      itemBtnClickData[item.SecUid] > 0 ? ("(" +
                        itemBtnClickData[item.SecUid] + ")") : "" }}</div>
                </div>
              </div> -->
              <div class="listColContainer2">
                <div class="operate1">
                  <div class="compereHomepageText" @click="openToBrowser(item.HomeUrl)">主页</div>
                  <!-- <div class="compereHomepageText" @click="openDirectory(item.SecUid)">目录</div> -->
                  <!-- <div :class="item.LiveStatus == 2 ? 'compereHomepageText' : 'notAllowedCompereHomepageText'"
                    @click="previewVideo(item.SecUid, item.LiveStatus)">预览</div> -->
                  <div
                    v-if="item.LiveStatus == 2 && (item.RecordStatus == 0 || item.RecordStatus == 2 || item.RecordStatus == 3) && detection && detectionBtnClickTime <= 0"
                    class="startRecordText" @click="startRecord(item.SecUid)"
                    :style="itemBtnClickData[item.SecUid] > 0 ? 'cursor: no-drop;' : ''">
                    录制{{ itemBtnClickData[item.SecUid] > 0 ? ("(" +
                      itemBtnClickData[item.SecUid] + ")") : "" }}</div>
                  <div v-if="item.RecordStatus == 1 && detectionBtnClickTime <= 0" class="endRecordText"
                    @click="stopRecord(item.SecUid)"
                    :style="itemBtnClickData[item.SecUid] > 0 ? 'cursor: no-drop;' : ''">
                    停录 {{
                      itemBtnClickData[item.SecUid] > 0 ? ("(" +
                        itemBtnClickData[item.SecUid] + ")") : "" }}</div>
                </div>
                <div class="operate2">
                  <div style="cursor: pointer;" @click="openDirectory(item.SecUid)">目录</div>
                  <div
                    :style="item.LiveStatus == 2 ? 'cursor: pointer; margin-left: 10px' : 'cursor: not-allowed; margin-left: 10px'"
                    @click="previewVideo(item.SecUid, item.LiveStatus)">预览</div>
                  <div style="cursor: pointer; margin-left: 10px" @click="removeCompere(item)">删除</div>
                </div>
              </div>

            </div>
          </div>
          <!-- 分页 -->
          <div style="margin-top: 10px;">
            <el-pagination style="text-align: center; margin-top: 10px" @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[200]" :page-size="pageSize"
              :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 列表没有数据 -->
      <div v-else class="emptyContainer">
        <div class="emptyTipText">当前还没有添加主播，请先添加主播</div>
        <div class="emptyTipBtn" @click="toAddCompere">添加主播</div>
      </div>

    </div>

    <!-- 行业弹窗 -->
    <el-dialog title="行业选择" :visible.sync="tardeDialogVisible" width="600px" :close-on-click-modal="false">
      <el-cascader v-model="tradeIdArr" :options="tradeTreeList" style="width: 100%;"
        :props="{ checkStrictly: true, expandTrigger: 'click', value: 'id', label: 'name' }" filterable
        placeholder="选择一个行业，提高关键词匹配准确性" @change="changeTradeHandle" ref="tradeCascader">
      </el-cascader>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tardeDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="tradeConfirm()">确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import myUtils from '../../../utils/utils';
export default {
  data() {
    return {
      dataForm: {
        anchorName: "",
        recordStatus: ""
      },
      pageIndex: 1,
      detection: false, // 是否已开启检测
      pageSize: 200,
      totalCount: 0,
      recordStatusList: [
        { value: -1, label: "全部" },
        { value: 0, label: "未录制" },
        { value: 1, label: "录制中" },
        { value: 2, label: "手动停止录制" },
        { value: 3, label: "录制完成" },
      ],
      compereList: [], // 主播列表
      recordNum: 0, // 正在录制的数量
      startDetectionTime: 0, // 开始检测的时间
      detectionTime: "0秒", // 已检测时间
      detectionTimeTimer: null, // 检测时间定时器
      listTimer: null, // 列表定时器
      definitionList: ["标清", "高清", "超清", "蓝光"],
      definition: 0,
      recordModeList: ["ts", "flv", "mp4"],
      recordMode: 0,
      configInfo: {}, // 系统设置信息
      detectionBtnClickTime: 0, // 检测按钮点击的时间，用于限制30秒后才能再次点击
      itemBtnClickData: {}, // 主播开启/结束录制按钮点击的时间，用于限制30秒后才能再次点击
      itemBtnClickTimerData: {}, // 主播开启/结束录制按钮限制时间的定时器
      tradeList: [],
      tradeTreeList: [],
      tardeDialogVisible: false,
      tradeIdArr: [],
      currentAnchorSecUid: "",
      userProperty: {
        monitorNum: 0,
        aiAnalysisTime: 0,
        anchorNum: 0,
        totalMonitorNum: 0,
        totalAiAnalysisTime: 0,
        totalAnchorNum: 0
      },
    };
  },

  mounted() {
    this.getTradeList();
    this.getUserProperty();
    // 获取配置信息
    this.getBasinSetupInfo();
    // 获取清晰度
    this.definition = localStorage.getItem("detection") ? parseInt(localStorage.getItem("detection")) : 0;
    this.recordMode = localStorage.getItem("recordMode") ? parseInt(localStorage.getItem("recordMode")) : 0;


    // 获取列表数据
    this.getDataList();

    // 开启列表定时器
    this.startListTimer();

    // 停止录制
    if (this.$store.state.stopRecord) {
      this.$store.commit("saveStopRecord", false);
      this.stopDetection();
    }
  },
  beforeDestroy() {
    // 清除列表定时器
    this.stopListTimer();
    // 清除检测时间定时器
    clearInterval(this.detectionTimeTimer);
    this.detectionTimeTimer = null;
  },

  methods: {
    // 跳转到设置页面
    toSetup() {
      this.$store.commit("saveSetupMenu", "customerService");
      this.$emit('updateMenuIndex', 4);
    },
    // 获取用户资产
    getUserProperty() {
      this.$httpClient.userProperty.info({}).then((res) => {
        if (res && res.code === 0) {
          this.userProperty = res.data;
        }
      });
    },
    // 确定选择行业
    tradeConfirm() {
      let requestData = {
        secUid: this.currentAnchorSecUid,
        tradeId: this.tradeIdArr.length > 0 ? this.tradeIdArr[this.tradeIdArr.length - 1] : "1"
      }

      this.$httpClient.compere.updateanchortrade(requestData).then((res) => {
        if (res && res.code === 0) {
          this.tardeDialogVisible = false;
        }
      });

    },
    // 选择行业回调
    changeTradeHandle() {
      // 关闭级联列表下拉
      this.$refs.tradeCascader.dropDownVisible = false;
    },
    // 显示行业弹窗
    showTrade(secUid) {
      this.currentAnchorSecUid = secUid;

      if (!this.tradeTreeList || this.tradeTreeList.length < 1) {
        this.$httpBack.trade.listTree({}).then((res) => {
          if (res && res.code === 0) {
            this.tradeTreeList = res.data;
            this.tardeDialogVisible = true;
          }
        });
      } else {
        this.tardeDialogVisible = true;
      }

    },
    // 获取行业列表
    getTradeList() {
      this.$httpBack.trade.list({ limit: -1 }).then((res) => {
        if (res && res.code == 0) {
          this.tradeList = res.data.list;
        }
      })
    },
    // 清除列表定时器
    stopListTimer() {
      if (this.listTimer) {
        clearInterval(this.listTimer);
        this.listTimer = null;
      }
    },
    // 开启列表定时器
    startListTimer() {
      if (!this.listTimer) {
        this.listTimer = setInterval(() => {
          this.getDataList();
        }, 1000);
      }
    },
    // 删除主播
    removeCompere(item) {
      if (item.RecordStatus == 1) {
        this.$message.error("主播正在录制中，请停止录制后再删除");
        return;
      }
      this.$confirm('将会连带删除该主播的视频, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$httpClient.compere.removeanchor({ secUid: item.SecUid }).then((res) => {
          if (res.code == 0) {
            this.$message.success("删除成功");
          }
        });
      });
    },
    // 获取基本设置信息
    getBasinSetupInfo() {
      this.$httpClient.setup.getmodel({}).then((res) => {
        if (res.code == 0) {
          this.configInfo = res.data;
          if (this.configInfo.IsRocord == 1) {
            // 正在检测中
            this.$store.commit("saveDetectionStatus", true);
            if (!this.$store.state.startDetectionTime) {
              this.$store.commit("saveDetectionTime", Date.now());
            }

          } else {
            // 没有在检测
            this.$store.commit("saveDetectionStatus", false);
            this.$store.commit("saveDetectionTime", null);
            // 清除定时器
            if (this.detectionTimeTimer) {
              clearInterval(this.detectionTimeTimer);
              this.detectionTimeTimer = null;
            }

          }
          // 设置检测状态
          this.detection = this.$store.state.detectionStatus;
          this.startDetectionTime = this.$store.state.startDetectionTime;

          if (this.detection && !this.detectionTimeTimer) {
            // 创建检测时间定时器
            this.detectionTimeTimer = setInterval(() => {
              this.detectionTime = myUtils.toformatTime(Date.now() - this.startDetectionTime);
            }, 1000);
          }
        }
      });
    },
    // 录制模式值改变回调
    recordModeChange(val) {
      localStorage.setItem("recordMode", val);
      if (val == 2) {
        this.$message.info("mp4模式录制可能会导致不稳定，建议选ts模式录制，录完之后再手动转mp4");
      }
    },
    // 清晰度值改变回调
    definitionChange(val) {
      localStorage.setItem("detection", val);
    },
    // 打开主播主页
    openToBrowser(url) {
      window.open(url, "_blank");
    },
    // 预览视频
    previewVideo(secUid, status) {
      if (status != 2) {
        this.$message.error("未开始检测或主播未开播");
        return;
      }
      this.$httpClient.compere.previewvideo({ secUid }).then((res) => {

      });
    },
    // 停止检测
    stopDetection() {

      // 限制30秒访问
      if (this.detectionBtnClickTime > 0) {
        this.$message.error("请等待倒计时结束后再操作");
        return;
      }

      this.$confirm('将停止所有主播录制，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$httpClient.compere.stopdecector({}).then((res) => {
          if (res.code == 0) {

            this.$store.commit("saveDetectionStatus", false);
            this.$store.commit("saveDetectionTime", null);
            this.detection = this.$store.state.detectionStatus;
            // 清除检测时间定时器
            clearInterval(this.detectionTimeTimer);
            this.detectionTimeTimer = null;
            this.$message.success("已停止录制");

            // 启动限制点击定时器
            this.detectionBtnClickTime = 30;
            this.detectionBtnClickInterval = setInterval(() => {
              // 创建定时器，每1秒执行一次
              this.detectionBtnClickTime -= 1;
              if (this.detectionBtnClickTime <= 0) {
                clearInterval(this.detectionBtnClickInterval);
                this.detectionBtnClickInterval = null;
              }
            }, 1000);
          }
        });
      });


    },
    // 开始检测
    startDetection() {

      // 限制30秒访问
      if (this.detectionBtnClickTime > 0) {
        this.$message.error("请等待倒计时结束后再操作");
        return;
      }

      if (!localStorage.getItem("diskTip")) {
        this.$httpClient.setup.getmaxdisk().then((res) => {
          if (res.code == 0 && res.data) {
            this.$confirm(res.data, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$store.commit("saveSetupMenu", "basicSetup");
              this.$emit('updateMenuIndex', 4);
              return;
            }).catch(() => {
              this.confirmStart();
            });
            localStorage.setItem("diskTip", 1);
          } else {
            this.confirmStart();
          }
        });
      } else {
        this.confirmStart();
      }

    },
    confirmStart() {
      this.$confirm('本软件是一款直播复盘分析软件，其作用是从目标网站上获取使用者或使用者所在公司直播间的公开视频源进行下载，包含但不限于视频、图文，文字等，这些内容为使用者或使用者所在公司的直播间信息。本软件的录制功能是全免费和公益性质的，仅提供给用户使用软件以复盘和学习为目的，切勿用于商业用途或侵害他人权利，由此产生的一切后果由使用者承担。', '免责声明', {
        confirmButtonText: '我已知晓',
        cancelButtonText: '忽略',
        type: 'warning'
      }).then(() => {
        let requestData = {
          VideoType: this.recordMode,
          Definition: this.definition
        }
        this.$httpClient.compere.decector(requestData).then((res) => {
          if (res.code == 0) {
            this.$store.commit("saveDetectionStatus", true);
            this.$store.commit("saveDetectionTime", Date.now());
            this.detection = this.$store.state.detectionStatus;
            this.startDetectionTime = this.$store.state.startDetectionTime;
            // 创建检测时间定时器
            this.detectionTimeTimer = setInterval(() => {
              this.detectionTime = myUtils.toformatTime(Date.now() - this.startDetectionTime);
            }, 1000);

            // 启动限制点击定时器
            this.detectionBtnClickTime = 30;
            this.detectionBtnClickInterval = setInterval(() => {
              // 创建定时器，每1秒执行一次
              this.detectionBtnClickTime -= 1;
              if (this.detectionBtnClickTime <= 0) {
                clearInterval(this.detectionBtnClickInterval);
                this.detectionBtnClickInterval = null;
              }
            }, 1000);
          }
          this.$message.success("已开启检测");
        });
      });
    },
    // 开启、关闭自动录制
    autoRecordChange(value, SecUid) {
      let requestData = {
        SecUid,
        isAuto: value ? 1 : 0
      }
      this.$httpClient.compere.openorcloseautorecord(requestData).then((res) => {
        if (res.code == 0) {
          this.$message.success("修改成功");
        }
      });
    },
    // 停止主播录制
    stopRecord(secUid) {
      if (this.itemBtnClickData[secUid] > 0) {
        this.$message.error("请等待倒计时结束后再操作");
        return;
      }
      // 启动限制点击定时器
      this.itemBtnClickData[secUid] = 30;
      this.itemBtnClickTimerData[secUid] = setInterval(() => {
        // 创建定时器，每1秒执行一次
        this.itemBtnClickData[secUid] -= 1;
        if (this.itemBtnClickData[secUid] <= 0) {
          clearInterval(this.itemBtnClickTimerData[secUid]);
          this.itemBtnClickTimerData[secUid] = null;
        }
      }, 1000);


      this.$httpClient.compere.stoprecord({ secUid }).then((res) => {
        if (res.code == 0) {
          this.$message.success("操作成功");
        }
      });
    },
    // 开启主播录制
    startRecord(secUid) {

      if (this.itemBtnClickData[secUid] > 0) {
        this.$message.error("请等待倒计时结束后再操作");
        return;
      }
      // 启动限制点击定时器
      this.itemBtnClickData[secUid] = 30;
      this.itemBtnClickTimerData[secUid] = setInterval(() => {
        // 创建定时器，每1秒执行一次
        this.itemBtnClickData[secUid] -= 1;
        if (this.itemBtnClickData[secUid] <= 0) {
          clearInterval(this.itemBtnClickTimerData[secUid]);
          this.itemBtnClickTimerData[secUid] = null;
        }
      }, 1000);

      this.$httpClient.compere.startRecord({ secUid }).then((res) => {
        if (res.code == 0) {
          this.$message.success("操作成功");
        }
      });
    },
    // 打开目录
    openDirectory(secUid) {
      this.$httpClient.compere.openfolder({ secUid }).then((res) => {
        console.log(res);
      });
    },
    // 跳转到添加主播页面
    toAddCompere() {
      this.$emit('updateMenuIndex', 1);
    },
    // 初始化页码
    keywordChange() {
      this.pageIndex = 1;

      this.initDataList();
    },
    // 初始化主播列表
    initDataList() {
      let requestData = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        anchorName: this.dataForm.anchorName ? encodeURIComponent(this.dataForm.anchorName) : "",
        recordStatus: this.dataForm.recordStatus === "" ? -1 : this.dataForm.recordStatus
      }

      // 清除列表定时器
      this.stopListTimer();

      let recordNum = 0;
      this.$httpClient.compere.getpageanchor(requestData).then((res) => {
        if (res.code == 0) {
          this.totalCount = res.data.Total;

          if (res.data.DataList && res.data.DataList.length > 0) {
            res.data.DataList.forEach(item => {
              item.IsAutoRecord = item.IsAutoRecord !== 0;
              // 统计正在录制数量
              if (item.RecordStatus == 1) {
                recordNum += 1;
              }
            })
          }
          this.compereList = res.data.DataList;
          this.recordNum = recordNum;
          // 开启列表定时器
          this.startListTimer();
        }
      });
    },
    // 获取主播列表
    getDataList() {
      let requestData = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        anchorName: this.dataForm.anchorName ? encodeURIComponent(this.dataForm.anchorName) : "",
        recordStatus: this.dataForm.recordStatus === "" ? -1 : this.dataForm.recordStatus
      }

      this.$httpClient.compere.getpageanchor(requestData).then((res) => {
        if (res.code == 0) {
          this.totalCount = res.data.Total;

          if (res.data.DataList && res.data.DataList.length > 0) {

            let recordNum = 0;
            res.data.DataList.forEach(newItem => {
              // 统计正在录制数量
              newItem.IsAutoRecord = newItem.IsAutoRecord !== 0;
              if (newItem.RecordStatus == 1) {
                recordNum += 1;
              }

              // 将新主播加进主播列表
              let exist = false;
              this.compereList.forEach(item => {
                if (item.SecUid == newItem.SecUid) {
                  exist = true;
                }
              });
              if (!exist) {
                this.compereList.push(newItem);
              }

            });

            this.recordNum = recordNum;

          } else {
            this.compereList = [];
            this.recordNum = 0;
          }

          // 将不存在的主播移出列表
          if (this.compereList && this.compereList.length > 0 && res.data.DataList && res.data.DataList.length > 0) {
            this.compereList.forEach((item, index) => {
              let exist = false;
              res.data.DataList.forEach(newItem => {
                if (newItem.SecUid == item.SecUid) {
                  exist = true;
                }
              });
              if (!exist) {
                this.compereList.splice(index, 1);
              }
            })
          }

          if (this.compereList && this.compereList.length > 0 && res.data.DataList && res.data.DataList.length > 0) {
            // 已经存在主播列表，只需替换不同的主播数据
            this.compereList.forEach((compereItem, index) => {
              let compereItemJson = JSON.stringify(compereItem);
              res.data.DataList.forEach(newCompereItem => {
                if (compereItem.SecUid == newCompereItem.SecUid) {
                  let newCompereItemJson = JSON.stringify(newCompereItem);
                  if (compereItemJson != newCompereItemJson) {
                    this.$set(this.compereList, index, newCompereItem)
                  }
                }
              })
            })
          }

          // 排序
          if (this.compereList && this.compereList.length > 0) {
            this.compereList.sort((a, b) => {
              const dateA = new Date(a.AddTime);
              const dateB = new Date(b.AddTime);
              return dateB - dateA;
            });

            let currentDate = new Date();
            let currentSecond = myUtils.toFormatDate(currentDate);
            this.compereList.forEach((item) => {
              // 如果主播状态为未开播，但是录制状态又为录制中，将录制状态改成录制完成
              if (item.LiveStatus == 4 && item.RecordStatus == 1) {
                item.RecordStatus = 3;
              }

              // 根据录制时长计算大小
              if (item.RecordStatus == 1 && item.CurrentRecordStartTime) {

                if (item.CurrentRecordStatus == 0) {
                  // 当前正在分段
                  if (item.Paragraph == 0) {
                    // 第0段，初始化内容
                    localStorage.setItem("recordSecond" + item.SecUid, 0);
                    localStorage.setItem("recordSizeCount" + item.SecUid, 0);
                    item.Duration = myUtils.toformatTime(0);
                    item.VedioSizie = "0";
                  } else {
                    // 直接获取存储的数据
                    let secondDifference = localStorage.getItem("recordSecond" + item.SecUid) ? parseInt(localStorage.getItem("recordSecond" + item.SecUid)) : 0;
                    item.Duration = myUtils.toformatTime((secondDifference + 1) * 1000);
                    item.VedioSizie = myUtils.retainDecimals(localStorage.getItem("recordSizeCount" + item.SecUid) ? parseFloat(localStorage.getItem("recordSizeCount" + item.SecUid)) : 0);
                  }

                } else {

                  let currentRecordSecond = myUtils.toSecondByDate(item.CurrentRecordStartTime);
                  // 时间差（秒）
                  let secondDifference = myUtils.toSecondByDate(currentSecond) - currentRecordSecond;

                  if ((this.configInfo.LimitType == 1 || this.configInfo.LimitType == 2) && secondDifference >= this.configInfo.LimitValue * 60) {
                    item.Duration = myUtils.toformatTime(this.configInfo.LimitValue * 60 * 1000);
                    item.VedioSizie = myUtils.retainDecimals(localStorage.getItem("recordSizeCount" + item.SecUid) ? parseFloat(localStorage.getItem("recordSizeCount" + item.SecUid)) : 0);
                  } else {
                    // 获取上次存储记录的秒数
                    let oldRecordSecond = localStorage.getItem("recordSecond" + item.SecUid) ? parseInt(localStorage.getItem("recordSecond" + item.SecUid)) : 0;
                    if (oldRecordSecond > secondDifference) {
                      // 存储的秒数比当前的时间差大，说明现在是新录制的一段
                      localStorage.setItem("recordSecond" + item.SecUid, secondDifference);
                      let size = secondDifference * (0.12 + Math.random() * 0.08);
                      localStorage.setItem("recordSizeCount" + item.SecUid, size);

                      item.VedioSizie = myUtils.retainDecimals(size);
                    } else {
                      // 获取上次存储的大小
                      let recordSizeCount = localStorage.getItem("recordSizeCount" + item.SecUid) ? parseFloat(localStorage.getItem("recordSizeCount" + item.SecUid)) : 0;

                      localStorage.setItem("recordSecond" + item.SecUid, secondDifference);
                      // 计算上次存储的秒跟当前秒的差值 * 随机数 + 之前的累计
                      let size = (secondDifference - oldRecordSecond) * (0.12 + Math.random() * 0.08) + recordSizeCount;
                      localStorage.setItem("recordSizeCount" + item.SecUid, size);

                      item.VedioSizie = myUtils.retainDecimals(size);
                    }

                    item.Duration = myUtils.toformatTime(secondDifference * 1000);
                  }

                }

              }
            })
          }

        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.initDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.initDataList();
    },
  },
};
</script>
<style scoped lang="less">
.operate2 {
  margin-top: 4px;
  display: flex;
  font-size: 14px;
  color: #0077FF;
}

// .operate2 {
//   margin-top: 4px;
//   display: flex;
// }

.operate1 {
  display: flex;
}

.emptyTipBtn {
  width: 90px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #0077FF;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #0077FF;
  margin-top: 16px;
  cursor: pointer;
}

.emptyTipText {
  font-weight: 400;
  font-size: 14px;
  color: #677583;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 152px);
}

.tableContainer::-webkit-scrollbar {
  display: none;
}

/* .tableContainer::-webkit-scrollbar {
  width: 8px;
}
.tableContainer::-webkit-scrollbar-thumb {
  background-color: #eaecf1;
  border-radius: 3px;
} */

.tableContainer {
  height: calc(100vh - 226px);
  overflow-y: auto;
}

.endRecordText {
  width: 64px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #FF3A19;
  font-size: 13px;
  color: #FF3A19;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.startRecordText {
  width: 64px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #0077FF;
  font-size: 13px;
  color: #0077FF;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notAllowedCompereHomepageText {
  width: 36px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #B4BCCA;
  font-size: 13px;
  color: #2E3742;
  text-align: center;
  cursor: not-allowed;
  margin-right: 7px;
}

.compereHomepageText {
  padding: 0 18px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #B4BCCA;
  font-size: 13px;
  color: #2E3742;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recordText {
  font-size: 13px;
  color: #2E3742;
  text-align: center;
}

.comperePlayStatusText {
  margin-left: 4px;
  font-weight: 500;
  font-size: 13px;
  color: #FF3270;
}

.comperePlayStatusImg {
  width: 18px;
}

.comperePlayStatus {
  display: flex;
  align-items: center;
}

.compereResourceType {
  background: #200B1C;
  border-radius: 8px;
  font-size: 10px;
  color: #FFFFFF;
  text-align: center;
  height: 15px;
  line-height: 15px;
  padding: 0 8px;
  margin-left: 5px;
}

.compereNotPlayStatus {
  font-size: 13px;
  color: #95A1AF;
}

.compereStatusContainer {
  display: flex;
  align-items: center;
}

.compereName {
  font-size: 14px;
  color: #2E3742;
}

.compereInfoContainer {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.compereImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.listColContainer2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 144px;
}

// .listColContainer2 {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: center;
//   width: 144px;
// }

.listColContainer1 {
  flex: 2;
  display: flex;
  align-items: center;
}

.listColContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.listRowContainer {
  display: flex;
  align-items: center;
  padding: 10px 13px;
}

.listTitleContainer {
  font-weight: 500;
  font-size: 12px;
  color: #2E3742;
  height: 28px;
  background: #F5F7F9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 13px;
  margin-top: 16px;
}

.el-form-item {
  margin-bottom: 0;
}

.stopDetectionContainer {
  font-weight: 500;
  font-size: 14px;
  color: #FF3A19;
  padding: 0 10px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #FF3A19;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}

.startDetectionContainer {
  font-weight: 500;
  font-size: 14px;
  color: #1997ff;
  padding: 0 10px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #1997ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.statusLeftContainer {
  font-size: 13px;
  color: #2E3742;
}

.statusContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.compereContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>